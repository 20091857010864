define("discourse/plugins/subrite-paywall/discourse/initializers/subrite", ["exports", "discourse/lib/plugin-api", "discourse-common/config/environment"], function (_exports, _pluginApi, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // function hasPermission(userGroups, topicPermissions) {
  //   console.log(userGroups, topicPermissions);
  //   // Check if any user group has permission
  //   return userGroups.some((group) =>
  //     topicPermissions.some((permission) => permission.group_name === group.name)
  //   );
  // }

  function isUserSubscribed(groups) {
    return groups.some(group => group.name === "subscribers");
  }
  var _default = _exports.default = {
    name: "subrite-paywall-initializer",
    initialize(container) {
      const user = container.lookup("current-user:main");
      const siteSettings = container.lookup("site-settings:main");
      console.log(siteSettings.subrite_paywall_enabled, siteSettings.subrite_checkout_url);
      if (!siteSettings.subrite_paywall_enabled || !siteSettings.subrite_checkout_url) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8.40", api => {
        if (user) {
          api.modifyClass("route:topic-from-params", {
            pluginId: "subrite-paywall",
            model(params) {
              params = params || {};
              params.track_visit = true;
              const topic = this.modelFor("topic");
              const purchaseUrl = new URL(siteSettings.subrite_checkout_url);
              purchaseUrl.searchParams.append("reference", topic.slug);
              purchaseUrl.searchParams.append("onCheckoutCompletedUrl", window.location.href);
              topic.purchase_url = purchaseUrl.toString();
              const postStream = topic.postStream;

              // I sincerely hope no topic gets this many posts
              if (params.nearPost === "last") {
                params.nearPost = 999999999;
              }
              params.forceLoad = true;
              return postStream.refresh(params).then(async () => {
                topic.is_user_subscribed = isUserSubscribed(user.groups);
                return params;
              }).catch(e => {
                if (!(0, _environment.isTesting)()) {
                  // eslint-disable-next-line no-console
                  console.log("Could not view topic", e);
                }
                params._loading_error = true;
                return params;
              });
            }
          });
        }
      });
    }
  };
});